import { Dispatch, Store } from 'redux';
import setPosts from '../../common/actions/set-posts';
import { getRecentPosts } from '../../common/selectors/recent-posts-selectors';
import { getRelatedPosts } from '../../common/selectors/related-posts-selectors';
import { setPostCount } from '../../common/store/post-count/set-posts-count';
import fetchRecentPosts from '../../post-page/actions/fetch-recent-posts';
import fetchRelatedPosts from '../../post-page/actions/fetch-related-posts';
import { getMainPost } from '../store/main-post';
import { AppState } from '../store/reducers';

export function fetchWidgetPosts() {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    const mainPost = getMainPost(getState());
    if (!mainPost) {
      dispatch(setPosts([]));
      dispatch(setPostCount(0));
      return;
    }

    // TODO: logic based on app-settings
    if (mainPost.relatedPostIds?.length) {
      await dispatch(fetchRelatedPosts(mainPost));
      const relatedPosts = getRelatedPosts(getState(), mainPost.id);
      dispatch(setPosts(relatedPosts));
      dispatch(setPostCount(relatedPosts.length));
      return;
    }

    await dispatch(fetchRecentPosts(mainPost));
    const recentPosts = getRecentPosts(getState(), mainPost.id);
    dispatch(setPosts(recentPosts));
    dispatch(setPostCount(recentPosts.length));
  };
}

export function listenToMainPostChanges(store: Store) {
  let mainPost = getMainPost(store.getState());
  store.subscribe(async () => {
    if (!mainPost) {
      return;
    }
    const newMainPost = getMainPost(store.getState());
    if (!newMainPost) {
      return;
    }
    // TODO: update when settings change
    if (mainPost.id !== newMainPost.id) {
      mainPost = newMainPost;
      await store.dispatch(fetchWidgetPosts());
    }
  });
}
